import React from "react";
import Form from "./form/form";
function PartTwoSection() {

    return (
        <div className="text-center">
            What activity would you like to do with me? (you can select multiple if you are unable to decide):
            <div className="w-full px-3 py-4">
                <Form/>
            </div>
        </div>
    );
}

export default PartTwoSection;
