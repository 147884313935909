import React, {createRef, useEffect, useState} from "react";
import "../../App.css";
import useStore from "../../store/useStore";
import PartOneSection from "./part-one/part-one-section";
import PartTwoSection from "./part-two/part-two-section";
import PartThreeSection from "./part-three/part-three-section";

const getWindowDimensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height,
    };
};

function Main() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const setNoButtonStyle = useStore((state) => state.setNoButtonStyle)
    const setTitleStyle = useStore((state) => state.setTitleStyle)
    const progressStep = useStore((state) => state.progressStep)
    const titleRef: React.RefObject<any> = createRef();

    const yesButtonStyle = {
        top: windowDimensions.height / 2,
        left: (windowDimensions.width / 2) * 0.8,
    };

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const getNewNoButtonPosition = () => {
        return {
            top: windowDimensions.height / 2,
            left: (windowDimensions.width / 2) * 1.2,
        };
    };

    const getNewTitlePosition = () => {
        return {top: (windowDimensions.height / 2) * 0.85, left: (windowDimensions.width - getTitleWidth()) / 2};
    };

    useEffect(() => {
        setNoButtonStyle(getNewNoButtonPosition());
        setTitleStyle(getNewTitlePosition());
    }, [windowDimensions]);

    const getTitleWidth = () => {
        return titleRef.current?.clientWidth;
    };

    return <div className="Main"> {progressStep === 0 ?
        <PartOneSection titleRef={titleRef} yesButtonStyle={yesButtonStyle}
                        windowDimensions={windowDimensions}/> : progressStep === 1 ? <PartTwoSection/> :
            <PartThreeSection/>}</div>;
}

export default Main;