import React, {createRef} from "react";
import useStore from "../../../../store/useStore";

interface NoButtonProps {
    windowDimensions: { height: number; width: number }
}

function NoButton({windowDimensions}: NoButtonProps) {
    const noButtonStyle = useStore((state) => state.noButtonStyle)
    const setNoButtonStyle = useStore((state) => state.setNoButtonStyle)
    const noIndex = useStore((state) => state.noIndex)
    const setNoIndex = useStore((state) => state.setNoIndex)
    const noOptions = useStore((state) => state.noOptions)
    const defaultActive = useStore((state) => state.defaultActive)
    const setDefaultActive = useStore((state) => state.setDefaultActive)
    const defaultNo = useStore((state) => state.defaultNo)
    const noButtonRef: React.RefObject<any> = createRef();

    function randomIntFromInterval(min: number, max: number) {
        // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    const getButtonHeight = () => {
        return noButtonRef.current.clientHeight;
    };

    const getButtonWidth = () => {
        return noButtonRef.current.clientWidth;
    };

    const changeNoButtonPosition = () => {
        const getNewTop = randomIntFromInterval(0, windowDimensions.height - getButtonHeight());
        const getNewLeft = randomIntFromInterval(0, windowDimensions.width - getButtonWidth());
        setNoButtonStyle({top: getNewTop, left: getNewLeft});
    };

    const handleOnClickNo = () => {
        changeNoButtonPosition();

        let nextIndex = noIndex + 1;
        const limit = noOptions.length;

        if (nextIndex === limit) {
            nextIndex = 0;
        }
        setNoIndex(nextIndex);
        setDefaultActive(false);
    };

    return <>
        <button
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded absolute z-0"
            style={noButtonStyle}
            onMouseEnter={() => changeNoButtonPosition()}
            ref={noButtonRef}
            onClick={() => handleOnClickNo()}
        >
            {defaultActive ? defaultNo : noOptions[noIndex]}
        </button>
    </>
}

export default NoButton;
