import React from "react";
import useStore from "../../../../../store/useStore";
import Button from "../../../../../reusable-components/button";

function ConfirmButton() {
    const loading = useStore((state) => state.loading)

    return (
    <Button text="Confirm" type="submit" disabled={loading} loading={loading} color="green" />
    );
}

export default ConfirmButton;
