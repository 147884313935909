import React from "react";
import ConfirmButton from "./form-sections/confirm-button";
import Activities from "./form-sections/activities";
import useStore from "../../../../store/useStore";
import axios from "axios";
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const customStyle = {
    fontSize: '17px' // Adjust the font size as needed
};

function Form() {
    const activities = useStore((state) => state.activities);
    const selectedActivities = useStore((state) => state.selectedActivities);
    const setLoading = useStore((state) => state.setLoading);
    const setProgressStep = useStore((state) => state.setProgressStep);

    const errorToast = (message: string) => {
        toast.error(message, {
            position: "top-right"
        });

    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!selectedActivities.some((activity) => activity)) {
            errorToast("Please select at least one activity");
            return;
        }

        const filterActivities = activities.filter((activity, i) => selectedActivities[i]);
        const activitiesCommaSeperated = filterActivities.map(activity => activity.label).join(',');
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/activities`, {
                activity: activitiesCommaSeperated
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': process.env.REACT_APP_API_KEY
                }
            });

            setLoading(false);
            setProgressStep(2);
        } catch (error) {
            setLoading(false);
            // @ts-ignore\
            errorToast(`${error.message}: ${error.response.data.error}`)
        }
    }

    return (
        <form className="rounded" onSubmit={handleSubmit}>
            <ToastContainer
                pauseOnHover={false}
                toastStyle={customStyle}/>
            <div className="mb-4 flex items-center justify-center">
                <Activities/>
            </div>
            <div className="mb-4 flex items-center justify-center">
                <ConfirmButton/>
            </div>
        </form>
    );
}

export default Form;
