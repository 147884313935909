import React from "react";
import useStore from "../../../../store/useStore";

interface QuestionProps {
    titleRef: React.RefObject<any>
}

function Question({titleRef}: QuestionProps) {
    const titleStyle = useStore((state) => state.titleStyle)
    const name = useStore((state) => state.name)

    return (
        <div className="absolute" ref={titleRef} style={titleStyle}>
            Hello {name}, would you like to go on a date with me? :)
        </div>
    );
}

export default Question;