import React from "react";
import {Spinner} from "flowbite-react";

type ButtonProps = {
    text: string,
    onClick?: () => void,
    type?: "button" | "submit" | "reset",
    disabled?: boolean,
    loading?: boolean,
    className?: string,
    color?: "blue" | "green" | "red",
    style?: React.CSSProperties,
    onMouseEnter?: () => void,
    ref?: React.RefObject<any>
};

const Button: React.FC<ButtonProps> = ({
                                           text,
                                           onClick,
                                           type = "button",
                                           disabled = false,
                                           loading = false,
                                           className = "",
                                           color = "blue",
                                           style = {},
                                           onMouseEnter,
                                           ref
                                       }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled || loading}
            className={`bg-${color}-500 text-white font-bold py-2 px-4 rounded ${!disabled && !loading ? `hover:bg-${color}-700` : ''} ${disabled || loading ? 'opacity-50 cursor-not-allowed' : ''} ${className} ${color === 'green' ? 'z-10' : ''}`}
            style={style}
            onMouseEnter={onMouseEnter}
            ref={ref}
        >
            {loading && <Spinner aria-label="Alternate spinner button example" size="md" className="mb-2 mr-3"/>}
            {loading ? 'Loading' : text}
        </button>
    );
};

export default Button;