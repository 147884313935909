import React from "react";
import "../../../App.css";
import Question from "./part-one-sections/question";
import YesButton from "./part-one-sections/yes-button";
import NoButton from "./part-one-sections/no-button";
import Button from "../../../reusable-components/button";
import {Spinner} from "flowbite-react";

interface PartOneSectionProps {
    titleRef: React.RefObject<any>
    yesButtonStyle: { top: number; left: number },
    windowDimensions: { width: number; height: number },
}

function PartOneSection({titleRef, yesButtonStyle, windowDimensions}: PartOneSectionProps) {

    return (
        <>
            <Question titleRef={titleRef}/>
            <YesButton yesButtonStyle={yesButtonStyle}/>
            <NoButton windowDimensions={windowDimensions}/>
        </>
    );
}

export default PartOneSection;