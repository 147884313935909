import React, { useState } from "react";
import {Spinner} from "flowbite-react";

type CheckboxGroupProps<T extends { label: string; value: string | number }> = {
    items: T[];
    onSelectionChange: (selectedItems: boolean[]) => void;
    loading: boolean;
};

const CheckboxGroup = <T extends { label: string; value: string | number }>({
                                                                                items,
                                                                                onSelectionChange,
                                                                                loading,
                                                                            }: CheckboxGroupProps<T>) => {
    const [selectedItems, setSelectedItems] = useState<boolean[]>(new Array(items.length).fill(false));

    const handleCheckboxClick = (index: number) => {
        const newSelectedItems = selectedItems.map((item, i) => (i === index ? !item : item));
        setSelectedItems(newSelectedItems);
        onSelectionChange(newSelectedItems);
    };

    return (
        <div className="relative w-48">
            {loading && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-10 z-10">
                    <Spinner aria-label="Loading spinner" size="lg" />
                </div>
            )}
            <ul className={`text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white ${loading ? 'opacity-50' : ''}`}>
                {items.map((item, index) => (
                    <li
                        className="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600"
                        key={item.value}
                    >
                        <div className="flex items-center ps-3">
                            <input
                                id={String(item.value)}
                                type="checkbox"
                                value={item.value}
                                checked={selectedItems[index]}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                onChange={() => handleCheckboxClick(index)}
                                disabled={loading}
                            />
                            <label
                                htmlFor={String(item.value)}
                                className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                {item.label}
                            </label>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CheckboxGroup;