import React from "react";
import useStore from "../../../../../store/useStore";
import CheckboxGroup from "../../../../../reusable-components/checkbox-group";

function Activities() {
    const activities = useStore((state) => state.activities)
    const setSelectedOptions = useStore((state) => state.setSelectActivities)
    const selectedActivities = useStore((state) => state.selectedActivities)
    const loading = useStore((state) => state.loading)

    const handleSelectionChange = (selectedItems: boolean[]) => {
        setSelectedOptions(selectedItems);
        console.log(selectedActivities);
    };

    return (
        <CheckboxGroup items={activities} onSelectionChange={handleSelectionChange} loading={loading}/>
    );
}

export default Activities;
