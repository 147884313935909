import { create } from 'zustand';

const noOptions = [
    "Are you sure?",
    "Really sure?",
    "Last chance!",
    "Think about it...",
    "Surely not?",
    "You might regret this!",
    "Give it another thought!",
    "Are you certainly sure?",
    "This could be a mistake!",
    "Have a heart!",
    "Don't be so cold!",
    "Change of heart?",
    "What if I say please?",
    "Wouldn't you reconsider?",
];

const activities = [
    {value: 1, label: "Picnicking"},
    {value: 2, label: "Cafe"},
    {value: 3, label: "Museum"},
    {value: 4, label: "Filing taxes together"},
    {value: 5, label: "Dining out"},
    {value: 6, label: "Arcade"},
    {value: 7, label: "Surprise me"},
];

type Position = {
    top: number;
    left: number;
}

export type Activity = {
    value: number;
    label: string;
}

type State = {
    name: string
    //windowDimensions: ??
    noButtonStyle: Position;
    titleStyle: Position;
    progressStep: number;
    noIndex: number;
    noOptions: string[];
    defaultActive: boolean;
    defaultNo: string;
    activities: Activity[];
    selectedActivities: boolean[];
    loading: boolean;
    remainingMinutes: number;
}

type Action = {
    setNoButtonStyle: (buttonStyle: Position) => void;
    setTitleStyle: (titleStyle: Position) => void;
    setProgressStep: (value: number) => void;
    setNoIndex: (index: number) => void;
    incrementNoIndex: () => void;
    setDefaultActive: (value: boolean) => void;
    setSelectActivities: (selectedActivities: boolean[]) => void;
    setLoading: (loading: boolean) => void;
}

const useStore = create<State & Action>((set) => ({
    name: "sayang",
    //windowDimensions: ??
    noButtonStyle: { top: 0, left: 0 },
    titleStyle: { top: 0, left: 0 },
    progressStep: 0,
    noIndex: 0,
    noOptions: noOptions,
    defaultActive: true,
    defaultNo: "No",

    activities: activities,
    selectedActivities: new Array(activities.length).fill(false),
    loading: false,
    remainingMinutes:0,

    setNoButtonStyle: (buttonStyle: Position) => set({ noButtonStyle: buttonStyle }),
    setTitleStyle: (titleStyle: Position) => set({ titleStyle: titleStyle }),
    setProgressStep: (value: number) => set({ progressStep: value }),
    setNoIndex: (index: number) => set({ noIndex: index }),
    incrementNoIndex: () => set((state: State) => ({
        noIndex: (state.noIndex + 1) % state.noOptions.length,
    })),
    setDefaultActive: (value: boolean) => set({ defaultActive: value }),
    setSelectActivities: (selectedActivities: boolean[]) => set({ selectedActivities: selectedActivities }),
    setLoading: (loading: boolean) => set({ loading }),
}));

export default useStore;