import React, {useEffect, useState} from "react";
import useStore from "../../../store/useStore";

function PartThreeSection() {
    const name = useStore((state) => state.name);
    const [remainingTime, setRemainingTime] = useState({ minutes: 0, seconds: 0 });

    const end = new Date('2024-11-05T05:45:00Z');

    useEffect(() => {
        const calculateRemainingTime = () => {
            const now = new Date();
            const diff = end.getTime() - now.getTime();
            const minutes = Math.floor(diff / 1000 / 60);
            const seconds = Math.floor((diff / 1000) % 60);
            setRemainingTime({ minutes, seconds });
        };

        calculateRemainingTime();
        const interval = setInterval(calculateRemainingTime, 1000); // Update every second

        return () => clearInterval(interval);
    }, [end]);

    return (
        <div>
            <div className="text-center">
                <div className="pb-5">
                Thank youuu {name}, I'm excited to do all kinds of activities with you!
                I've been notified of what you entered. Rest assured as my minions are already busy planning our next date.
                </div>
                <img
                    src="https://media.tenor.com/Do2tfm6klgQAAAAM/cat-kitten.gif"
                    alt="Jumping cat meme"
                    width="220"
                    height="220"
                    className="m-auto"
                />
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
            <div className="text-sm text-center">
                I miss you (just a tiny bit) and I am patiently waiting till your return.
                <div className="text-sm text-center">
                    Only {remainingTime.minutes} minutes
                    and {remainingTime.seconds} seconds left
                </div>
            </div>
        </div>
    )
}

export default PartThreeSection;
