import React from "react";
import useStore from "../../../../store/useStore";
import Button from "../../../../reusable-components/button";


interface YesButtonProps {
    yesButtonStyle: { top: number; left: number }
}

function YesButton({yesButtonStyle}: YesButtonProps) {
    const setProgressStep = useStore((state) => state.setProgressStep)

    const handleOnClickYes = () => {
        setProgressStep(1)
    };

    return <Button text="Yes" color="green"
                   style={{position: 'absolute', top: yesButtonStyle.top, left: yesButtonStyle.left}}
                   onClick={() => handleOnClickYes()}/>;

}

export default YesButton;
